/**
 * create unique id
 */
const getUniqId = () => {
  const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
  const N = 32
  const session_id =
    process.env.PREFIX +
    Array.from(crypto.getRandomValues(new Uint8Array(N)))
      .map(n => S[n % S.length])
      .join("")
  return session_id
}
export default getUniqId
