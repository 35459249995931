export const Config = {
  siteName: "F-Formen",
  productId: 6,
  companyId: 1,
  deliverTraderId: 2,
  purchaseMethod: "ウェブ",
  amazonFlg: false,
  deliverStartCount: 6,
  deliverEndCount: 21,
  amzUrl: "https://www.amazon.co.jp/dp/B079CHDNT2",
  productInfo: [
    {
      id: 44,
      img: "1_03.png",
      img_sm: "f_A06cv03.png",
      variation: 1,
    },
    {
      id: 45,
      img: "2_03.png",
      img_sm: "f_A07cv03.png",
      variation: 2,
    },
    {
      id: 46,
      img: "3_03.png",
      img_sm: "f_A08cv03.png",
      variation: 3,
    },
    {
      id: 47,
      img: "5_03.png",
      img_sm: "f_A09cv03.png",
      variation: 5,
    },
  ],
  counts: [
    { value: 1, label: "1個" },
    { value: 2, label: "2個" },
    { value: 3, label: "3個" },
    { value: 4, label: "4個" },
    { value: 5, label: "5個" },
  ],
}

export const ExceptParams = [
  "access_token",
  "token_type",
  "expires_in",
  "scope",
  "token",
  "session_id",
  "width",
  "height",
  "guid",
  "aclid",
  "gclid",
  "Affiliator",
  "MerchantName",
  "stars_ses_id",
]
