/**
 * get parameter
 */
import { ExceptParams } from "../../site.config"

const getParameter = () => {
  let urlParamStr = window.location.search
  const arg = sessionStorage.getItem("arg")
  const val = sessionStorage.getItem("val")

  if (urlParamStr && !arg && !val) {
    urlParamStr = urlParamStr.substring(1)
    urlParamStr.split("&").forEach(param => {
      const temp = param.split("=")
      if (
        temp[0] &&
        temp[1] &&
        ExceptParams.includes(temp[0]) === false &&
        !temp[0].match(/[A-Z]/)
      ) {
        sessionStorage.setItem("arg", temp[0])
        sessionStorage.setItem("val", temp[1])
      }
    })
  }
}
export default getParameter
