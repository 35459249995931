import axios from "axios"

/**
 * add access count & get affiliate data
 */
const addAccessCount = async device => {
  if (sessionStorage.getItem("arg") && sessionStorage.getItem("val")) {
    const session_id = sessionStorage.getItem("session_id")
    const arg = sessionStorage.getItem("arg")
    const val = sessionStorage.getItem("val")
    const requestUri = `${process.env.API_ANALYZE_URL}?sess=${session_id}&arg=${arg}&data=${val}&pid=3&ca=${device}`
    const res = await axios.get(requestUri)
    sessionStorage.setItem("affiliate", JSON.stringify(res.data))
  }
}
export default addAccessCount
