/**
 * get user agent
 */
const getUserAgent = () => {
  const ua = window.navigator.userAgent.toLowerCase()
  let device
  if (
    ua.indexOf("iphone") > 0 ||
    ua.indexOf("ipod") > 0 ||
    (ua.indexOf("android") > 0 && ua.indexOf("mobile") > 0)
  ) {
    device = 5
  } else if (ua.indexOf("ipad") > 0 || ua.indexOf("android") > 0) {
    // iOS12 まで
    device = 5
  } else if (
    ua.indexOf("ipad") > -1 ||
    (ua.indexOf("macintosh") > -1 && "ontouchend" in document)
  ) {
    // iOS13 以降
    device = 5
  } else {
    device = 0
  }
  return device
}

export default getUserAgent
